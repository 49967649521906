export default () => ({
  id: '',
  name: '',
  avatar: {
    name: '',
    path: '',
  },
  gender: '',
  document: '',
  active: true,
  students: [],
  user: {
    id: '',
    email: '',
    password: ''
  }
})