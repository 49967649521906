import http from './http'

export const find = (id) => {
  return http.get('companions/find', {
    params: {id}
  })
}

export const programs = (id) => {
  return http.get('companions/programs', {
    params: {id}
  })
}

export const attendances = (id) => {
  return http.get('companions/attendances', {
    params: {id}
  })
}

export const reports = (id) => {
  return http.get('companions/reports', {
    params: {id}
  })
}

export const save = (data) => {
  return http.post('companions/save', {
    id: data.id,
    name: data.name,
    avatar: data.avatar,
    birthday: data.birthday,
    gender: data.gender,
    comments: data.comments,
    active: data.active,
  })
}

export const finish = (data) => {
  return http.put('companions/finish', {
    id: data.id
  })
}

export const maintenance = (data) => {
  return http.put('companions/maintenance', {
    id: data.id
  })
}

const view = (id) => {
  return http.get('companions/view', {
    params: {id}
  })
}

const create = (data) => {
  return http.post('companions/create', data)
}

const update = (data) => {
  return http.post('companions/update', data)
}

const remove = (data) => {
  return http.post('companions/delete', data)
}

export default {
  find,
  view,
  create,
  update,
  remove,
  programs,
  attendances,
  reports,
  save,
  finish,
  maintenance,
}