<template>
  <v-dialog scrollable fullscreen persistent transition="dialog-bottom-transition" :value="value">
    <v-form @submit.prevent="onSubmit">
      <v-card tile>
        <v-card-title class="pa-0">
          <v-toolbar flat dark color="primary">
            <v-btn icon dark @click="onClose">
              <v-icon>mdi-close</v-icon>
            </v-btn>
            <v-toolbar-title>Acompanhante</v-toolbar-title>
          </v-toolbar>
        </v-card-title>
        <v-loading v-model="loading">
          <v-card-text>
            <v-container fluid class="px-0">
              <v-row align="center">
                <v-col cols="auto">
                  <template v-if="form.avatar.path">
                    <v-avatar size="60" color="grey">
                      <v-img :src="form.avatar.path"/>
                    </v-avatar>
                  </template>
                  <template v-else>
                    <v-avatar size="60" color="grey">
                      <v-icon color="white" size="40">mdi-account</v-icon>
                    </v-avatar>
                  </template>
                </v-col>
                <v-col cols="auto">
                  <avatar-uploader @success="onAvatarUploadSuccess">
                    Alterar Foto
                  </avatar-uploader>
                </v-col>
              </v-row>
              <v-row>
                <v-col cols="12">
                  <v-checkbox hide-details class="pt-0 mt-0" label="Habilitado" v-model="form.active"/>
                </v-col>
                <v-col cols="12">
                  <v-text-field dense outlined hide-details :error="!!errors.name" label="Nome" v-model="form.name"/>
                </v-col>
                <v-col cols="12">
                  <v-select dense outlined hide-details label="Gênero" :error="!!errors.gender" :items="genderLists" v-model="form.gender"/>
                </v-col>
                <v-col cols="12">
                  <v-text-field dense outlined hide-details label="CPF" v-model="form.document"/>
                </v-col>
                <v-col cols="12">
                  <v-text-field dense outlined hide-details type="email" label="E-mail" v-model="form.user.email"/>
                </v-col>
                <v-col cols="12">
                  <v-text-field dense outlined hide-details obscore label="Senha" :type="typePassword" :append-icon="iconPassword" v-model="form.user.password" @click:append="toggleShowPassword"/>
                </v-col>
              </v-row>
              <v-row>
                <v-col cols="12">
                  <h3>Estudantes</h3>
                  <template v-if="!!errors.students && errors.students.length">
                    <p class="ma-0 red--text">Selecione pelo menos um estudante.</p>
                  </template>
                </v-col>
                <v-col cols="12">
                  <v-card outlined max-height="250">
                    <v-flex class="overflow-auto" :style="{maxHeight: '210px'}">
                      <v-list dense class="pa-0">
                        <template v-for="student in studentsLists">
                          <v-list-item :key="student.id">
                            <v-list-item-action>
                              <v-checkbox :value="student" v-model="form.students"/>
                            </v-list-item-action>
                            <v-list-item-content>
                              <v-list-item-title>{{ student.name }}</v-list-item-title>
                            </v-list-item-content>
                          </v-list-item>
                          <v-divider :key="`divider-${student.id}`"/>
                        </template>
                      </v-list>
                    </v-flex>
                  </v-card>
                </v-col>
              </v-row>
            </v-container>
          </v-card-text>
          <v-card-actions class="tertiary">
            <v-btn text @click="onClose">Fechar</v-btn>
            <v-spacer/>
            <v-btn depressed :disabled="saving" :loading="saving" type="submit" color="primary">Salvar</v-btn>
          </v-card-actions>
        </v-loading>
      </v-card>
    </v-form>
  </v-dialog>
</template>

<script>
  import CompanionService from '../../services/companion'
  import UserService from '../../services/user'
  import AvatarUploader from '../../components/AvatarUploader'
  import CompanionForm from '../../models/CompanionForm'
  import genderOptions from '../../models/GenderOptions'

  export default {
    props: ['value', 'id'],
    components: {
      AvatarUploader,
    },
    data: () => ({
      saving: false,
      loading: false,
      showPassword: false,
      errors: {},
      companion: {},
      students: [],
      form: CompanionForm(),
      genderLists: genderOptions(),
    }),
    watch: {
      value(value) {
        if (value) {
          this.mountData()
        }
      }
    },
    methods: {
      mountData() {
        this.errors = []
        this.form = CompanionForm()
        this.fetchInitial()
      },
      fetchInitial() {
        this.loading = true

        Promise.all([
          UserService.students()
            .then(data => {
              this.students = data
            }),
        ]).then(() => this.loading = false)
      },
      onClose() {
        this.$emit('input', false)
      },
      onSubmit() {
        this.saving = true
        this.errors = {}

        CompanionService.create(this.form)
          .then(() => {
            this.$root.$emit('snackbar', {
              text: 'Dados salvos com sucesso.',
              color: 'green',
            })
            this.onClose()
          })
          .catch(data => {
            this.$root.$emit('snackbar', {
              text: 'Não foi possível salvar os dados.',
              color: 'red',
            })
            this.errors = data.errors || {}
          })
          .then(() => this.saving = false)
      },
      onAvatarUploadSuccess(data) {
        this.form.avatar = data
      },
      toggleShowPassword() {
        this.showPassword = !this.showPassword
      },
    },
    computed: {
      iconPassword() {
        return this.showPassword ? 'mdi-eye-off' : 'mdi-eye'
      },
      typePassword() {
        return this.showPassword ? 'text' : 'password'
      },
      studentsLists() {
        return this.students.map(student => ({
          id: student.id,
          name: student.name
        }))
      }
    }
  }
</script>