<template>
  <v-container>
    <v-loading v-model="loading">
        <v-list-item class="px-2">
        <v-list-item-content>
          <v-list-item-title class="title">
            Acompanhantes
          </v-list-item-title>
        </v-list-item-content>
        <v-list-item-action>
          <v-btn fab depressed small color="primary" @click="createDialog = true">
            <v-icon>mdi-plus</v-icon>
          </v-btn>
        </v-list-item-action>
      </v-list-item>
      <v-card outlined>
        <v-simple-table>
          <thead>
            <tr class="text-uppercase">
              <th colspan="100">Nome</th>
            </tr>  
          </thead>    
          <tbody>
            <template v-for="companion in companions">
              <tr :key="companion.id">
                <td>{{ companion.name }}</td>
                <td class="text-right">
                  <v-btn icon @click="onEdit(companion)">
                    <v-icon>mdi-square-edit-outline</v-icon>
                  </v-btn>
                  <v-btn icon :loading="deleting === companion.id" :disabled="deleting === companion.id" @click="onDelete(companion)">
                    <v-icon>mdi-trash-can-outline</v-icon>
                  </v-btn>
                </td>
              </tr>
            </template>
          </tbody>
        </v-simple-table>
      </v-card>
    </v-loading>
    <edit-dialog :id="companion.id" v-model="editDialog" />
    <create-dialog v-model="createDialog" />
  </v-container>
</template>

<script>
  import UserService from '../../services/user'
  import CompanionService from '../../services/companion'
  import EditDialog from './edit'
  import CreateDialog from './create'

  export default {
    components: {
      EditDialog,
      CreateDialog,
    },
    data: () => ({
      loading: false,
      deleting: null,
      editDialog: false,
      createDialog: false,
      companion: {},
      companions: [],
    }),
    watch: {
      editDialog(value) {
        if (!value) {
          this.fetchStart()
        }
      },
      createDialog(value) {
        if (!value) {
          this.fetchStart()
        }
      }
    },
    methods: {
      onEdit(companion) {
        this.companion = companion
        this.editDialog = true
      },
      onCreate() {
      },
      onDelete(companion) {
        if (confirm('Deseja realmente remover este Acompanhante?')) {  
          this.deleting = companion.id;
          CompanionService.remove({
            id: companion.id,
          }).then(() => {
            this.fetchStart();
          }).catch(() => alert('Não foi possível remover o Acompanhante!'))
          .then(() => {
            this.deleting = null;    
          });
        }
      },
      fetchStart() {
        this.loading = true

        UserService.companions()
          .then((data) => {
            this.companions = data
            this.loading = false
          })
      }
    },
    mounted() {
      this.fetchStart()
    }
  }
</script>
